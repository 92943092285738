import React from 'react'
import withTranslation from 'next-translate/withTranslation'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Notification } from 'react-notification'
import { AdsList } from '../src/modules/ads/list/components'
import { PanelMobile } from '../src/modules/ads/list/components/PanelMobile'
import { HomePagination } from '../src/modules/ads/pagination/components'
import { AdsHeader } from '../src/modules/ads/filter/components'
import { GroupsMenuMobile } from '../src/modules/categories/components'
import { getUserPackageInactiveMsg } from '../src/modules/users/Profile/selectors'
import { setUserPackageInactiveMsg } from '../src/modules/users/Profile/actions'
import { adsTypes, globalOptions } from '../src/core/constants'
import { fetchAds } from '../src/modules/ads/list/actions'
import { getPaginationParams } from '../src/modules/ads/pagination/selectors'
import { setPaginationParams } from '../src/modules/ads/pagination/actions'
import { getPageMetaTags } from '../src/modules/common/selectors'
import { Page, Page404 } from '../src/ui'
import PropTypes from 'prop-types'
import { CookiesHelper } from '../src/core/cookiesHelper/CookiesHelper'
import initialState from '../src/modules/redux/initialState'
import { deepCopy, flattenWithChildren } from '../src/utils'
import { setCategoriesAndGroups } from 'src/modules/categories/actions'
import { fetchCountries } from 'SRC/modules/geoObjects/countries/actions'
import { fetchCitiesByCountryWithinRegions } from '../src/modules/geoObjects/cities/actions'
import config from '../src/config/config.json'

class IndexPage extends React.Component {
  static async getInitialProps (ctx) {
    const status = 'Published'
    const sortBy = 'dateDesc'

    const {store, query, req, isServer} = ctx

    if (!query) query = {}

    let { page, type, category, ...restQuery } = query

    const locale = ctx?.locale?.toUpperCase() || query?.locale?.toUpperCase() || 'ME'

    page = Number(page) || 1

    type = type || adsTypes.paid

    restQuery = restQuery || null

    const isMobileDevice = store.getState().common.isMobileDevice

    let limit = globalOptions.adsCountPerPage
    
    if (type === adsTypes.paid) {
      limit = !isMobileDevice ? globalOptions.paidAdsCountPerPage : globalOptions.paidAdsCountPerPageMobile
    } else {
      limit = !isMobileDevice ? globalOptions.adsCountPerPage : globalOptions.adsCountPerPageMobile
    }

    const isPageExists = !isNaN(page) && (type === adsTypes.all || type === adsTypes.free || type === adsTypes.paid)

    if (isPageExists) {
      const requestParams = restQuery
        ? { ...restQuery, page, type, limit, status, sortBy }
        : { page, type, limit, status, sortBy }

      const paginationParams = {
        ...store.getState().ads.pagination.params,
        currentPage: Number(page),
        adsType: type,
        countPerPage: limit
      }

      if (config.pages.index.defaultFilterCategory[locale?.toUpperCase()] && !isMobileDevice) {
        await store.dispatch(setCategoriesAndGroups(config.pages.index.defaultFilterCategory[locale?.toUpperCase()].seo, locale?.toUpperCase()))
      }

      await store.dispatch(fetchAds({ ...requestParams, page: requestParams.page, locale: locale?.toUpperCase() || 'ME' }, paginationParams))
    }

    const pageMetaTags = getPageMetaTags(store.getState())

    const findPage = pageMetaTags.find(item => item.page === '/')

    let title = ''

    if (isPageExists) {
      title = findPage ? findPage.title : `${config.pages.index.title} | ${config.pages.defaultTitle}`
    } else {
      title = config.pages.notFoundTitle
    }

    return {
      title,
      isPageExists,
      isMobileDevice,
      type,
      noAdsMessage: config.pages.index.noPaidAds,
      state: IndexPage.getPageState(store, isPageExists)
    }
  }

  static getPageState (store, isPageExists = false) {
    const state = deepCopy(initialState)
    if (store) {
      const currentState = store.getState()

      state.user.auth.isAuthorized = currentState.user.auth.isAuthorized
      state.user.profile.error = currentState.user.profile.error
      state.user.profile.info = currentState.user.profile.info
      state.user.profile.favouriteAds = currentState.user.profile.favouriteAds
      state.user.profile.savedFilters = currentState.user.profile.savedFilters
      state.user.profile.favouriteProfiles = currentState.user.profile.favouriteProfiles
      state.user.profile.packageInactiveMessage = currentState.user.profile.packageInactiveMessage
      state.user.profile.priceTypes = currentState.user.profile.priceTypes
      state.user.listSidebar = currentState.user.listSidebar

      state.common.transitions = currentState.common.transitions
      state.common.router = currentState.common.router
      state.common.is404 = !isPageExists
      state.common.isMobileDevice = currentState.common.isMobileDevice
      state.common.userTypes = currentState.common.userTypes
      state.common.pageMetaTags = currentState.common.pageMetaTags
      state.common.pageMetaTagsSingle = currentState.common.pageMetaTagsSingle

      state.groups.elements = currentState.groups.elements

      state.categories.currentSubcategory = currentState.categories.currentSubcategory

      if (isPageExists) {
        state.ads.list = currentState.ads.list
        state.ads.brands = currentState.ads.brands
        state.ads.pagination = currentState.ads.pagination
        state.ads.products = currentState.ads.products
        state.ads.equipment = currentState.ads.equipment
        state.ads.detail.reportTypes = currentState.ads.detail.reportTypes
        state.ads.detail.registrationFormulas = currentState.ads.detail.registrationFormulas
      }

      state.geoObjects.countries = currentState.geoObjects.countries
      state.geoObjects.regions = currentState.geoObjects.regions
      state.geoObjects.cities = currentState.geoObjects.cities

      state.form = currentState.form
    }
    return state
  }

  render () {
    const {
      pagination,
      isPageExists,
      noAdsMessage,
      packageInactiveMsg,
      setUserPackageInactiveMsg,
      isMobileDevice,
      i18n: { t }
    } = this.props

    return isPageExists
      ? <Page>
        {!isMobileDevice ? <AdsHeader /> : <GroupsMenuMobile />}
        <AdsList
          adsType={pagination.adsType}
          enabledInfiniteScroll={pagination.adsType === adsTypes.paid}
          noAdsMessage={t('common:noPaidAds')}
          showBreadCrumbs={false}
        />
        {this.props.pagination.adsType === adsTypes.free ? <HomePagination /> : null}
        {isMobileDevice ? <PanelMobile /> : null}
        <Notification
          isActive={packageInactiveMsg}
          message={packageInactiveMsg}
          action={'Sakrij'}
          onClick={() => { setUserPackageInactiveMsg('') }}
        />
      </Page>
      : <Page404 />
  }
}

const mapStateToProps = (state) => ({
  pagination: getPaginationParams(state),
  packageInactiveMsg: getUserPackageInactiveMsg(state)
})

IndexPage.propTypes = {
  pagination: PropTypes.shape({
    adsType: PropTypes.oneOf([
      adsTypes.paid,
      adsTypes.all,
      adsTypes.free
    ]).isRequired
  }),
  isPageExists: PropTypes.bool.isRequired,
  noAdsMessage: PropTypes.string.isRequired,
  packageInactiveMsg: PropTypes.string.isRequired,
  setUserPackageInactiveMsg: PropTypes.func.isRequired
}

IndexPage.defaultProps = {
  isPageExists: false
}

const enhance = compose(
  withTranslation,
  connect(mapStateToProps, { setUserPackageInactiveMsg })
)

export default enhance(IndexPage)
